import React from 'react'
import './style.css'
import CDDForm from 'components/CDDForm'

import { parse } from 'query-string'
import { Redirect } from 'react-router'
import { decode } from 'jsonwebtoken'

const CDD = ({ location }) => {
  try {
    let jwt = parse(location.search).jwt
    let payload = decode(jwt)
    if (!payload.formId || !payload.userId || !payload.identityId || !payload.apiKey || !payload.redirectURL) {
      return <Redirect to="/404" />
    }
    return (
      <div className="cdd">
        <CDDForm
          jwt={jwt}
          formId={payload.formId}
          userId={payload.userId}
          identityId={payload.identityId}
          apiKey={payload.apiKey}
          redirectURL={payload.redirectURL}
        />
      </div>
    )
  } catch (err) {
    return <Redirect to="/404" />
  }
}

export default CDD

import React from 'react'
import { ErrorMessage } from 'formik'
import { string, object, oneOfType, array, bool, func } from 'prop-types'
import { CustomInput, UncontrolledTooltip } from 'reactstrap'
import './style.css'

class Agreement extends React.Component {
  state = {
    isRead: false,
  }
  handleScroll = o => {
    o = o.srcElement
    if (o.offsetHeight + o.scrollTop === o.scrollHeight) {
      this.setState({ isRead: true })
    }
  }

  componentDidMount() {
    this.scroll.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    this.scroll.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const props = this.props
    return (
      <div className="agreement-form">
        <label id={props.name + '_label'}>
          {props.label}
          {props.isRequired && <span className="is-required">*</span>}
          <ErrorMessage name={props.name} component="span" className="field-error" />
        </label>
        <div className="agreement-wrapper">
          <div ref={e => (this.scroll = e)} className="agreement-scroll-wrapper">
            <ol>
              {props.options.map(({ text }) => (
                <li>{text}</li>
              ))}
            </ol>
          </div>
          <UncontrolledTooltip placement="top" target={'tooltip_' + props.name}>
            {props.tooltip}
          </UncontrolledTooltip>
          <div className="agreement-tooltip-wrapper" id={'tooltip_' + props.name}>
            <CustomInput
              className="agreement-checkbox"
              type="checkbox"
              id={props.name}
              label={props.checkboxLabel}
              disabled={false}
              onChange={e => {
                props.setFieldValue && props.setFieldValue(props.name, e.target.checked)
                props.onChange && props.onChange(e.target.checked)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

Agreement.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  checkboxLabel: string.isRequired,
  options: array.isRequired,
  tooltip: string.isRequired,
  setFieldValue: func,
  onChange: func,
  disabled: bool,
  isRequired: bool,
}

export default Agreement

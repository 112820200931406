import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Container, Row, Col } from 'reactstrap'
import moment from 'moment'
import { Formik } from 'formik'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import nationalities from '../../constants/countries'
import occupations from '../../constants/occupations'
import { createForm } from '../Form/FormGenerator'
import SubmitSchema from './Schema'
import { VerifySubmit } from '../../services/api'
import selfieImg from '../../static/selfie.svg'
import idCaudImg from '../../static/idcard.svg'
import laserImg from '../../static/laser.png'

const CreateIdentityForm = createForm()

const initialValues = {
  nationality: '',
  first_name_th: '',
  middle_name_th: '',
  last_name_th: '',
  first_name_en: '',
  middle_name_en: '',
  last_name_en: '',
  prefix: '',
  sex: '',
  birth_date: '',
  phone: '',
  occupation: '',
  address: '',
  sub_district: '',
  district: '',
  province: '',
  zip_code: '',
  isSameAddress: false,
  current_address: '',
  current_sub_district: '',
  current_district: '',
  current_province: '',
  current_zip_code: '',
  national_id: '',
  laser_code: '',
  passport_number: '',
  selfie_img: '',
  id_img: '',
  is_us_citizen: '',
  is_us_resident_for_tax: '',
  us_tax_number: '',
}

class IdentityForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isThai: false,
      selfie_img: '',
      id_img: '',
      isSame: false,
      is_us_citizen: false,
      is_us_resident_for_tax: false,
    }
  }

  handleSelectNational = setFieldValue => value => {
    this.setState({ isThai: value === 'TH' })
    this.clearDependencyFields(setFieldValue, [
      'first_name_th',
      'middle_name_th',
      'last_name_th',
      ...this.addresses,
      ...this.currentAddresses,
    ])
  }

  clearDependencyFields = (setFieldValue, fields) => {
    fields.forEach(item => {
      setFieldValue(item, '')
    })
  }

  handleSetFile = (name, value) => {
    this.setState({ [name]: value })
  }

  addresses = ['sub_district', 'district', 'province', 'zip_code']
  currentAddresses = ['current_sub_district', 'current_district', 'current_province', 'current_zip_code']

  onSelectAddress = setFieldValue => fullAddress => {
    const label = ['subdistrict', 'district', 'province', 'zipcode']
    this.addresses.forEach((name, item) => setFieldValue(name, fullAddress[label[item]]))
  }
  onSelectCurrentAddress = setFieldValue => fullAddress => {
    const label = ['subdistrict', 'district', 'province', 'zipcode']
    this.currentAddresses.forEach((name, index) => setFieldValue(name, fullAddress[label[index]]))
  }
  setSameAddress = setFieldValue => (isSame, values) => {
    this.setState({ isSame: isSame })
  }

  onChangeCondition = (name, value) => {
    this.setState({ [name]: value })
  }

  onReset = () => {
    this.setState({
      isThai: false,
      selfie_img: '',
      id_img: '',
      isSame: false,
      is_us_citizen: false,
      is_us_resident_for_tax: false,
    })
  }

  notify = text =>
    toast.error(text, {
      toastId: text,
      className: 'error-background',
      bodyClassName: 'error-background',
      progressClassName: 'fancy-progress-bar',
    })

  handleSubmit = (values, actions) => {
    const { intl } = this.props
    if (this.state.isSame) {
      values.current_address = values.address
      this.currentAddresses.forEach((name, index) => {
        values[name] = values[this.addresses[index]]
      })
    }
    if (!(this.state.is_us_citizen || this.state.is_us_resident_for_tax)) {
      values.us_tax_number = ''
    }
    values.birth_date = moment
      .utc(values.birth_date)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toJSON()
    let bodyFormData = new FormData()
    bodyFormData.set('form_data', JSON.stringify(values))
    bodyFormData.append('id_card', this.state.id_img)
    bodyFormData.append('person', this.state.selfie_img)
    VerifySubmit(bodyFormData)
      .then(res => {
        actions.resetForm(initialValues)
        this.onReset()
        actions.setSubmitting(false)
      })
      .catch(err => {
        actions.setSubmitting(false)
        let fields = err.response.data.fields
        this.notify(intl.formatMessage({ id: 'error.invalid_format' }))
        Object.keys(fields).forEach(key => {
          switch (fields[key].message) {
            case 'must be in a valid format':
              fields[key].message = intl.formatMessage({ id: 'error.invalid_format' })
              break
            case 'The parameter is required':
              fields[key].message = intl.formatMessage({ id: 'error.required' })
              break
            case 'Thai ID Card is malformed: ':
              fields[key].message = intl.formatMessage({ id: 'error.thai_id_malformed' })
              break
            case 'Thai ID Card is invalid: ':
              fields[key].message = intl.formatMessage({ id: 'error.invalid' })
              break
            default:
              fields[key].message = intl.formatMessage({ id: 'error.invalid' })
              break
          }
          actions.setFieldError(key, fields[key].message)
        })
      })
  }

  render() {
    const { intl } = this.props
    return (
      <>
        <ToastContainer hideProgressBar />
        <Container>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <div className="session">
                <h3 className="identity-header-form">
                  <FormattedMessage id="identity.title" />
                </h3>

                <Formik
                  initialValues={initialValues}
                  validationSchema={() => SubmitSchema(intl)}
                  onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions)
                  }}
                  render={({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                    <CreateIdentityForm
                      id="createIdentity"
                      formitems={[
                        {
                          type: 'header',
                          name: 'nationality_header',
                          label: <FormattedMessage id="identityForm.nationality_header" />,
                          number: 1,
                          span: 12,
                        },
                        {
                          type: 'select',
                          name: 'nationality',
                          label: <FormattedMessage id="identityForm.nationality" />,
                          onChange: this.handleSelectNational(setFieldValue),
                          options: nationalities.map(ele => ({
                            value: ele.alpha_2_code,
                            label: `${ele.nationality} (${ele.alpha_2_code})`,
                          })),
                          value: values.nationality,
                          setFieldValue: setFieldValue,
                          isRequired: true,
                          placeholder: intl.formatMessage({ id: 'identityForm.select' }),
                        },
                        {
                          type: 'header',
                          name: 'personal_information',
                          label: <FormattedMessage id="identityForm.personal_information" />,
                          number: 2,
                          span: 12,
                        },
                        {
                          type: 'input',
                          name: 'first_name_th',
                          label: (
                            <>
                              <FormattedMessage id="identityForm.first_name" />
                              &nbsp; (<FormattedMessage id="identityForm.th" />)
                            </>
                          ),
                          placeholder: intl.formatMessage({ id: 'identityForm.first_name' }),
                          value: values.first_name_th,
                          isRequired: this.state.isThai,
                          setFieldValue: setFieldValue,
                          span: 4,
                          disabled: !this.state.isThai,
                        },
                        {
                          type: 'input',
                          name: 'middle_name_th',
                          label: (
                            <>
                              <FormattedMessage id="identityForm.middle_name" />
                              &nbsp; (<FormattedMessage id="identityForm.th" />)
                            </>
                          ),
                          value: values.middle_name_th,
                          placeholder: intl.formatMessage({ id: 'identityForm.middle_name' }),
                          setFieldValue: setFieldValue,

                          span: 4,
                          disabled: !this.state.isThai,
                        },
                        {
                          type: 'input',
                          name: 'last_name_th',
                          label: (
                            <>
                              <FormattedMessage id="identityForm.last_name" />
                              &nbsp; (<FormattedMessage id="identityForm.th" />)
                            </>
                          ),
                          value: values.last_name_th,
                          placeholder: intl.formatMessage({ id: 'identityForm.last_name' }),
                          span: 4,
                          setFieldValue: setFieldValue,
                          isRequired: this.state.isThai,
                          disabled: !this.state.isThai,
                        },

                        {
                          type: 'input',
                          name: 'first_name_en',
                          label: (
                            <>
                              <FormattedMessage id="identityForm.first_name" />
                              &nbsp; (<FormattedMessage id="identityForm.en" />)
                            </>
                          ),
                          value: values.first_name_en,
                          placeholder: intl.formatMessage({ id: 'identityForm.first_name' }),
                          setFieldValue: setFieldValue,
                          span: 4,
                          isRequired: true,
                        },
                        {
                          type: 'input',
                          name: 'middle_name_en',
                          label: (
                            <>
                              <FormattedMessage id="identityForm.middle_name" />
                              &nbsp; (<FormattedMessage id="identityForm.en" />)
                            </>
                          ),
                          value: values.middle_name_en,
                          placeholder: intl.formatMessage({ id: 'identityForm.middle_name' }),
                          setFieldValue: setFieldValue,

                          span: 4,
                        },
                        {
                          type: 'input',
                          name: 'last_name_en',
                          label: (
                            <>
                              <FormattedMessage id="identityForm.last_name" />
                              &nbsp; (<FormattedMessage id="identityForm.en" />)
                            </>
                          ),
                          value: values.last_name_en,
                          placeholder: intl.formatMessage({ id: 'identityForm.last_name' }),
                          setFieldValue: setFieldValue,
                          span: 4,
                          isRequired: true,
                        },
                        {
                          type: 'select',
                          name: 'prefix',
                          label: <FormattedMessage id="identityForm.prefix" />,
                          value: values.prefix,
                          setFieldValue: setFieldValue,
                          isRequired: true,
                          placeholder: intl.formatMessage({ id: 'identityForm.select' }),
                          options: [
                            {
                              value: 'Mr.',
                              label: intl.formatMessage({ id: 'identityForm.mr' }),
                            },
                            {
                              value: 'Mrs.',
                              label: intl.formatMessage({ id: 'identityForm.mrs' }),
                            },
                            {
                              value: 'Ms.',
                              label: intl.formatMessage({ id: 'identityForm.miss' }),
                            },
                          ],
                        },
                        {
                          type: 'select',
                          name: 'sex',
                          label: <FormattedMessage id="identityForm.sex" />,
                          value: values.sex,
                          setFieldValue: setFieldValue,
                          options: [
                            { value: 'male', label: intl.formatMessage({ id: 'identityForm.male' }) },
                            { value: 'female', label: intl.formatMessage({ id: 'identityForm.female' }) },
                          ],
                          placeholder: intl.formatMessage({ id: 'identityForm.select' }),
                          isRequired: true,
                        },
                        {
                          type: 'date',
                          name: 'birth_date',
                          label: <FormattedMessage id="identityForm.birth_date" />,
                          placeholder: 'birthday',
                          value: values.birth_date,
                          setFieldValue: setFieldValue,
                          isRequired: true,
                        },
                        {
                          type: 'input',
                          name: 'phone',
                          label: <FormattedMessage id="identityForm.phone" />,
                          value: values.phone,
                          placeholder: '0xxxxxxxxx',
                          setFieldValue: setFieldValue,
                          isRequired: true,
                        },
                        {
                          type: 'select',
                          name: 'occupation',
                          label: intl.formatMessage({ id: 'identityForm.occupation' }),
                          value: values.occupation,
                          setFieldValue: setFieldValue,
                          isRequired: true,
                          placeholder: intl.formatMessage({ id: 'identityForm.select' }),
                          options: occupations.map(ele => ({
                            value: ele.full_name,
                            label: intl.formatMessage({ id: 'identityForm.occupation.' + ele.name }),
                          })),
                          otherValue: 'Other Industry',
                        },
                        {
                          type: 'header',
                          name: 'address_header',
                          label: <FormattedMessage id="identityForm.address" />,
                          number: 3,
                          span: 12,
                        },
                        {
                          type: 'input',
                          name: 'address',
                          label: <FormattedMessage id="identityForm.id_address" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.id_address' }),
                          value: values.address,
                          setFieldValue: setFieldValue,
                          span: 12,
                          isRequired: true,
                        },
                        {
                          type: 'address',
                          name: 'sub_district',
                          addressType: 'subdistrict',
                          label: <FormattedMessage id="identityForm.sub_district" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.sub_district' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectAddress(setFieldValue),
                          value: values.sub_district,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                        },
                        {
                          type: 'address',
                          name: 'district',
                          addressType: 'district',
                          label: <FormattedMessage id="identityForm.district" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.district' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectAddress(setFieldValue),
                          value: values.district,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                        },
                        {
                          type: 'address',
                          name: 'province',
                          addressType: 'province',
                          label: <FormattedMessage id="identityForm.province" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.province' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectAddress(setFieldValue),
                          value: values.province,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                        },
                        {
                          type: 'address',
                          name: 'zip_code',
                          addressType: 'zipcode',
                          label: <FormattedMessage id="identityForm.zip_code" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.zip_code' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectAddress(setFieldValue),
                          value: values.zip_code,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                        },
                        {
                          type: 'checkbox',
                          name: 'isSameAddress',
                          label: <FormattedMessage id="identityForm.same_address" />,
                          value: values.isSameAddress,
                          setFieldValue: setFieldValue,
                          onChange: this.setSameAddress(setFieldValue),
                          span: 12,
                        },
                        {
                          type: 'input',
                          name: 'current_address',
                          label: <FormattedMessage id="identityForm.current_address" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.current_address' }),
                          setFieldValue: setFieldValue,
                          value: this.state.isSame ? values.address : values.current_address,
                          span: 12,
                          isRequired: true,
                          disabled: this.state.isSame,
                          isVisible: !this.state.isSame,
                        },
                        {
                          type: 'address',
                          name: 'current_sub_district',
                          addressType: 'subdistrict',
                          label: <FormattedMessage id="identityForm.sub_district" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.sub_district' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectCurrentAddress(setFieldValue),
                          value: values.current_sub_district,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                          isVisible: !this.state.isSame,
                        },
                        {
                          type: 'address',
                          name: 'current_district',
                          addressType: 'district',
                          label: <FormattedMessage id="identityForm.district" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.district' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectCurrentAddress(setFieldValue),
                          value: values.current_district,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai || this.state.isSame,
                          isVisible: !this.state.isSame,
                        },
                        {
                          type: 'address',
                          name: 'current_province',
                          addressType: 'province',
                          label: <FormattedMessage id="identityForm.province" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.province' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectCurrentAddress(setFieldValue),
                          value: values.current_province,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                          isVisible: !this.state.isSame,
                        },
                        {
                          type: 'address',
                          name: 'current_zip_code',
                          addressType: 'zipcode',
                          label: <FormattedMessage id="identityForm.zip_code" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.zip_code' }),
                          setFieldValue: setFieldValue,
                          onSelect: this.onSelectCurrentAddress(setFieldValue),
                          value: values.current_zip_code,
                          isRequired: this.state.isThai,
                          isThai: this.state.isThai,
                          disabled: !this.state.isThai,
                          isVisible: !this.state.isSame,
                        },
                        {
                          type: 'header',
                          name: 'verification',
                          label: <FormattedMessage id="identityForm.verification" />,
                          number: 4,
                          span: 12,
                        },
                        {
                          type: 'input',
                          name: 'national_id',
                          label: <FormattedMessage id="identityForm.id_card" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.id_card' }),
                          value: values.national_id,
                          setFieldValue: setFieldValue,
                          isRequired: this.state.isThai,
                        },
                        {
                          type: 'input',
                          name: 'laser_code',
                          label: <FormattedMessage id="identityForm.laser_code" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.laser_code' }),
                          value: values.laser_code,
                          setFieldValue: setFieldValue,
                          isRequired: this.state.isThai,
                          tooltip: (
                            <>
                              <img src={laserImg} alt="laser example" />
                            </>
                          ),
                        },
                        {
                          type: 'input',
                          name: 'passport_number',
                          label: <FormattedMessage id="identityForm.passport" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.passport' }),
                          value: values.passport_number,
                          setFieldValue: setFieldValue,
                          isRequired: !this.state.isThai,
                        },
                        {
                          type: 'uploadImg',
                          name: 'selfie_img',
                          label: <FormattedMessage id="identityForm.selfie_img" />,
                          buttonLabel: intl.formatMessage({ id: 'identityForm.upload_img' }),
                          image: this.state.selfie_pre_img,
                          span: 12,
                          value: values.selfie_img,
                          setFieldValue: setFieldValue,
                          setImg: this.handleSetFile,
                          isRequired: true,
                          placeholder: selfieImg,
                        },
                        {
                          type: 'uploadImg',
                          name: 'id_img',
                          label: <FormattedMessage id="identityForm.id_img" />,
                          buttonLabel: intl.formatMessage({ id: 'identityForm.upload_img' }),
                          image: this.state.id_pre_img,
                          span: 12,
                          value: values.id_img,
                          setFieldValue: setFieldValue,
                          setImg: this.handleSetFile,
                          isRequired: true,
                          placeholder: idCaudImg,
                        },

                        {
                          type: 'header',
                          name: 'FATCA',
                          label: 'FATCA',
                          number: 5,
                          describe: (
                            <>
                              <FormattedMessage id="identityForm.condition" />(
                              <FormattedMessage id="identityForm.read_more" />
                              <a href="https://www.irs.gov/" rel="noopener noreferrer" target="_blank">
                                www.irs.gov
                              </a>
                              )
                            </>
                          ),
                          span: 12,
                        },
                        {
                          type: 'quest',
                          name: 'is_us_citizen',
                          label: <FormattedMessage id="identityForm.is_us_citizen" />,
                          options: [
                            {
                              value: true,
                              text: <FormattedMessage id="identityForm.yes" />,
                            },
                            {
                              value: false,
                              text: <FormattedMessage id="identityForm.no" />,
                            },
                          ],
                          span: 12,
                          setFieldValue: setFieldValue,
                          isRequired: true,
                          value: values.is_us_citizen,
                          onChange: this.onChangeCondition,
                        },
                        {
                          type: 'quest',
                          name: 'is_us_resident_for_tax',
                          label: <FormattedMessage id="identityForm.is_us_resident_for_tax" />,
                          options: [
                            {
                              value: true,
                              text: <FormattedMessage id="identityForm.yes" />,
                            },
                            {
                              value: false,
                              text: <FormattedMessage id="identityForm.no" />,
                            },
                          ],
                          span: 12,
                          isRequired: true,
                          value: values.is_us_resident_for_tax,
                          setFieldValue: setFieldValue,
                          onChange: this.onChangeCondition,
                        },
                        {
                          type: 'input',
                          name: 'us_tax_number',
                          label: <FormattedMessage id="identityForm.us_tax_number" />,
                          placeholder: intl.formatMessage({ id: 'identityForm.us_tax_number' }),
                          value: values.us_tax_number,
                          setFieldValue: setFieldValue,
                          isRequired: true,
                          isVisible: this.state.is_us_citizen || this.state.is_us_resident_for_tax,
                        },
                        {
                          type: 'submit',
                          name: 'submit',
                          label: <FormattedMessage id="identityForm.submit" />,
                          handleSubmit: handleSubmit,
                          isSubmitting: isSubmitting,
                          span: 12,
                        },
                      ]}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default injectIntl(IdentityForm)

import React from 'react'
import './style.css'
import { Field, ErrorMessage } from 'formik'
import { string, object, oneOfType, bool, func } from 'prop-types'
import Tooltip from '../Tooltip'
const Input = props => {
  return (
    <div className="inputGroup">
      <label id={props.name + '_label'}>
        {props.label}
        {props.isRequired && <span className="is-required">*</span>}
      </label>
      {props.tooltip && <Tooltip name={props.name} tooltip={props.tooltip} />}
      <Field
        className="inputForm"
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        disabled={props.disabled}
        value={props.value}
        onChange={e => {
          props.setFieldValue(props.name, e.target.value)
        }}
      />
      <ErrorMessage name={props.name} component="div" className="field-error" />
    </div>
  )
}

Input.propTypes = {
  name: string.isRequired,
  placeholder: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  setFieldValue: func.isRequired,
  value: string.isRequired,
  isRequired: bool,
  disabled: bool,
  tooltip: oneOfType([object, string]),
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.isRequired === nextProps.isRequired &&
    prevProps.value === nextProps.value
  )
}

export default React.memo(Input, areEqual)

import React from 'react'
import ReactDOM from 'react-dom'
import qs from 'qs'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Identity from './routes/Identity'
import CDD from './routes/CDD'
import NotFound from './routes/NotFound'
import * as serviceWorker from './serviceWorker'
import { IntlProvider, addLocaleData } from 'react-intl'
import { languages } from '../package.json'
import 'bootstrap/dist/css/bootstrap.min.css'

const getDefaultLanguage = () => {
  if (window.location.search !== '') {
    const lang = qs.parse(window.location.search, { ignoreQueryPrefix: true }).lang
    if (languages.indexOf(lang) > -1) {
      return lang
    }
  }
  return languages[0]
}

// add locale
languages.map(language => {
  addLocaleData(require(`react-intl/locale-data/${language}`))
})

const locale = getDefaultLanguage()

ReactDOM.render(
  <IntlProvider locale={locale} messages={require(`./translations/locales/${locale}.json`)}>
    <BrowserRouter>
      <Switch>
        <Route path="/identity" component={Identity} />
        <Route path="/cdd" component={CDD} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'
import './style.css'
import { bool } from 'prop-types'

const SubmitButton = props => {
  return (
    <div className="submit-button-wrapper">
      <button
        type="submit"
        className="submit-button"
        onClick={event => {
          event.preventDefault()
          props.handleSubmit()
        }}
        disabled={props.disabled || props.isSubmitting}
      >
        {props.isSubmitting && (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <div className="submit-label">{props.children || props.label}</div>
      </button>
    </div>
  )
}

SubmitButton.propTypes = {
  disabled: bool,
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.handleSubmit === nextProps.handleSubmit &&
    prevProps.isSubmitting === nextProps.isSubmitting
  )
}

export default React.memo(SubmitButton, areEqual)

import * as Yup from 'yup'

const SubmitSchema = intl => {
  return Yup.lazy(values => {
    const requiredMsg = intl.formatMessage({ id: 'error.required' })
    const schema = {}
    Object.keys(values).forEach(quest => {
      schema[quest] = Yup.number().required(requiredMsg)
    })
    return Yup.object().shape(schema)
  })
}
export default SubmitSchema

import React from 'react'
import './style.css'
import { ErrorMessage } from 'formik'
import { string, object, oneOfType, func, bool } from 'prop-types'

const DatePicker = props => {
  return (
    <div className="inputGroup">
      <label id={props.name + '_label'}>
        {props.label}
        {props.isRequired && <span className="is-required">*</span>}
      </label>
      <input
        className="inputForm"
        type="date"
        id={props.name}
        name={props.name}
        onChange={e => {
          props.setFieldValue(props.name, e.target.value)
        }}
        value={props.value}
        disabled={props.disabled}
      />
      <ErrorMessage name={props.name} component="div" className="field-error" />
    </div>
  )
}

DatePicker.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  setFieldValue: func.isRequired,
  isRequired: bool,
  disabled: bool,
}
function areEqual(prevProps, nextProps) {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.isRequired === nextProps.isRequired &&
    prevProps.value === nextProps.value
  )
}

export default React.memo(DatePicker, areEqual)

import React from 'react'
import './style.css'
import { number, string, object, oneOfType } from 'prop-types'

const HeaderForm = props => {
  return (
    <>
      <div className="header-div" id={props.name + '_label'}>
        <h4 className="number"> {props.number} </h4>
        <h4>{props.label}</h4>
      </div>
      {props.describe ? <div className="head-describe">{props.describe}</div> : ''}
    </>
  )
}

HeaderForm.propTypes = {
  name: string.isRequired,
  number: number.isRequired,
  label: oneOfType([object, string]).isRequired,
  describe: oneOfType([object, string]),
}

export default HeaderForm

import React from 'react'
import './style.css'
import { FormattedMessage } from 'react-intl'

function NotFound() {
  return (
    <div className="app">
      <FormattedMessage id="not_found" />
    </div>
  )
}

export default NotFound

import React from 'react'
import './style.css'
import IdentityForm from 'components/IdentityForm'

function Identity() {
  return (
    <div className="identity">
      <IdentityForm />
    </div>
  )
}

export default Identity

import React from 'react'
import { connect } from 'formik'

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik
    const keys = Object.keys(errors)
    if (keys.length > 0 && isSubmitting && isValidating) {
      const selector = `[id="${keys[0]}_label"]`
      const errorElement = document.querySelector(selector)
      window.scrollTo({
        top: window.pageYOffset + errorElement.getBoundingClientRect().top - 20,
      })
    }
  }

  render() {
    return null
  }
}

export default connect(ErrorFocus)

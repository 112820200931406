import React from 'react'
import { string, object, oneOfType } from 'prop-types'
import { Tooltip as TooltipReactstrap } from 'reactstrap'

import './style.css'

class Tooltip extends React.Component {
  state = {
    tooltipOpen: false,
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.tooltipOpen !== nextState.tooltipOpen
  }

  render() {
    const props = this.props

    return (
      <div style={{ display: 'inline-block' }} id={'tooltip_' + props.name}>
        <div className="input-help">?</div>
        <TooltipReactstrap
          className="tooltip-input-help"
          placement="bottom"
          isOpen={this.state.tooltipOpen}
          autohide={false}
          toggle={this.toggle}
          target={'tooltip_' + props.name}
        >
          {props.tooltip}
        </TooltipReactstrap>
      </div>
    )
  }
}

Tooltip.propTypes = {
  name: string.isRequired,
  tooltip: oneOfType([object, string]).isRequired,
}

export default Tooltip

import React from 'react'
import './style.css'
import { ErrorMessage } from 'formik'
import { string, object, oneOfType, array, bool, func } from 'prop-types'
import { Col, CustomInput } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

const Question = props => {
  return (
    <div className="questionFrom">
      <label id={props.name + '_label'} autofocus>
        {props.label}
        {props.isRequired && <span className="is-required">*</span>}
        <ErrorMessage name={props.name} component="span" className="field-error" />
      </label>
      <div>
        {props.options.map(({ value, text }, index) => (
          <CustomInput
            type="radio"
            id={props.name + index}
            key={props.name + index}
            name={props.name}
            checked={props.value === value}
            onChange={() => {
              props.setFieldValue(props.name, value)
              props.onChange && props.onChange(props.name, value)
            }}
            label={text}
            disabled={props.disabled}
          />
        ))}
      </div>
    </div>
  )
}

Question.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  value: string.isRequired,
  options: array.isRequired,
  disabled: bool,
  isRequired: bool,
  onChange: func,
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.isRequired === nextProps.isRequired &&
    prevProps.value === nextProps.value
  )
}
export default React.memo(Question, areEqual)

export const RenderQuestionPlaceholder = (numQuest, numChoices) => {
  return Array(numQuest)
    .fill()
    .map(() => {
      return (
        <Col>
          <div className="question-placeholder">
            <Skeleton width={250} />
            {Array(numChoices)
              .fill()
              .map((e, index) => {
                return (
                  <div>
                    <span className="choices-placeholder">
                      <Skeleton circle={true} height={16} width={16} />
                    </span>
                    <Skeleton width={150} />
                  </div>
                )
              })}
          </div>
        </Col>
      )
    })
}

import React from 'react'
import './style.css'
import { string, object, oneOfType, bool, func } from 'prop-types'
import { CustomInput } from 'reactstrap'

const Checkbox = props => {
  return (
    <div className="checkbox">
      <CustomInput
        type="checkbox"
        id={props.name + '_label'}
        label={props.label}
        checked={props.value}
        disabled={props.disabled}
        onChange={e => {
          props.onChange(e.target.checked, props.values)
          props.setFieldValue(props.name, e.target.checked)
        }}
      />
    </div>
  )
}

Checkbox.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  value: bool.isRequired,
  setFieldValue: func.isRequired,
  onChange: func.isRequired,
  disabled: bool,
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.isRequired === nextProps.isRequired
  )
}

export default React.memo(Checkbox, areEqual)

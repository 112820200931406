import axios from 'axios'
import { URL, API_KEY } from '../constants/kyc'
import urljoin from 'url-join'

axios.defaults.headers['X-Api-Key'] = API_KEY
export const VerifySubmit = bodyFormData => {
  return axios({
    method: 'post',
    url: urljoin(URL, 'identities/verify'),
    data: bodyFormData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
}

export const getQuestion = (formId, apiKey) => {
  axios.defaults.headers['X-Api-Key'] = apiKey
  return axios({
    method: 'get',
    url: urljoin(URL, 'cdd', 'forms', formId.toString(), '/'),
  })
}

export const submitAnswer = (data, formId, apiKey, jwt) => {
  axios.defaults.headers['X-Api-Key'] = apiKey
  axios.defaults.headers['JWT'] = jwt
  return axios({
    method: 'post',
    url: urljoin(URL, 'cdd', 'forms', formId.toString(), 'submit', '/'),
    data: data,
  })
}

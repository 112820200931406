import React from 'react'
import './style.css'
import { Field, ErrorMessage } from 'formik'
import { string, object, oneOfType, bool, func } from 'prop-types'
import InputAddress from 'react-thailand-address-autocomplete'

const onChange = (name, setFieldValue) => e => {
  setFieldValue(name, e.target.value)
}

const Address = props => {
  return (
    <div className="inputGroup">
      <label id={props.name + '_label'}>
        {props.label}
        {props.isRequired && <span className="is-required">*</span>}
      </label>
      <div className="InputAddress">
        {props.isThai ? (
          <InputAddress
            id={props.name}
            name={props.name}
            address={props.addressType}
            placeholder={props.placeholder}
            value={props.value}
            onChange={onChange(props.name, props.setFieldValue)}
            onSelect={props.onSelect}
          />
        ) : (
          <Field
            className="inputForm"
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
        )}
      </div>
      <ErrorMessage name={props.name} component="div" className="field-error" />
    </div>
  )
}

Address.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  addressType: string.isRequired,
  placeholder: string.isRequired,
  setFieldValue: func.isRequired,
  onSelect: func.isRequired,
  value: string.isRequired,
  isRequired: bool,
  disabled: bool,
  isThai: bool,
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.isRequired === nextProps.isRequired &&
    prevProps.value === nextProps.value &&
    prevProps.isThai === nextProps.isThai
  )
}

export default React.memo(Address, areEqual)

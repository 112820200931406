import React from 'react'
import './style.css'
import { ErrorMessage, Field } from 'formik'
import { string, object, oneOfType, func, bool } from 'prop-types'

class UploadImg extends React.Component {
  constructor(props) {
    super(props)
    this.state = { img: '' }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.img !== nextState.img ||
      this.props.value !== nextProps.value ||
      this.props.disabled !== nextProps.disabled ||
      this.props.isRequired !== nextProps.isRequired
    )
  }

  handleChange = event => {
    const target = event.target
    this.setState({ img: URL.createObjectURL(target.files[0]) })
  }
  render() {
    return (
      <div className="inputGroup" id={this.props.name + '_label'}>
        <label>
          {this.props.label}
          {this.props.isRequired && <span className="is-required">*</span>}
          <ErrorMessage name={this.props.name} component="span" className="field-error" />
        </label>
        <div className="img-wrapper">
          {this.state.img !== '' && this.props.value !== '' ? (
            <img alt="" src={this.state.img} />
          ) : (
            this.props.placeholder && <img alt="" src={this.props.placeholder} />
          )}
        </div>
        <div className="wrapper">
          <div className="upload-btn-wrapper">
            <button className="button-img">{this.props.buttonLabel}</button>
            <Field
              type="file"
              className="input-img"
              accept="image/*"
              name={this.props.name}
              onChange={event => {
                this.handleChange(event)
                this.props.setImg(this.props.name, event.target.files[0])
                this.props.setFieldValue(this.props.name, event.target.value)
              }}
              disabled={this.props.disabled && 'disabled'}
            />
          </div>
        </div>
      </div>
    )
  }
}

UploadImg.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  buttonLabel: string.isRequired,
  value: string.isRequired,
  setFieldValue: func.isRequired,
  setImg: func.isRequired,
  isRequired: bool,
  disabled: bool,
  placeholder: string,
}

export default UploadImg

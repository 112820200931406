import React from 'react'
import { number, string, shape, arrayOf, oneOf, object, func, oneOfType, bool } from 'prop-types'
import Input from './Input'
import SelectComponent from './Select'
import HeaderForm from './HeaderForm'
import DatePicker from './DatePicker'
import { Container, Row, Col } from 'reactstrap'
import UploadImg from './UploadImg'
import Question from './Question'
import SubmitButton from './SubmitButton'
import { Form } from 'formik'
import ErrorFocus from './ErrorFocus/ErrorFocus'
import Address from './Address'
import Checkbox from './Checkbox'
import Agreement from './Agreement'

class FormGenerator extends React.Component {
  renderSearchItemByType = (type, item) => {
    switch (type) {
      case 'header':
        return <HeaderForm {...item} />
      case 'input':
        return <Input {...item} />
      case 'select':
        return <SelectComponent {...item} />
      case 'address':
        return <Address {...item} />
      case 'checkbox':
        return <Checkbox {...item} />
      case 'uploadImg':
        return <UploadImg {...item} />
      case 'date':
        return <DatePicker {...item} />
      case 'quest':
        return <Question {...item} />
      case 'agreement':
        return <Agreement {...item} />
      case 'submit':
        return <SubmitButton {...item} />
      default:
        throw new Error(`Component Type ${type} is not support. please check searchItems data.`)
    }
  }

  renderFormItems = formItems =>
    formItems.map(({ type, isVisible = true, ...item }) => {
      return (
        isVisible && (
          <Col key={item.name} xs={12} sm={item.span || this.props.defaultspan}>
            <div label={item.name}>{this.renderSearchItemByType(type, item)}</div>
          </Col>
        )
      )
    })

  handleReset = (onSearch = () => {}) => {
    this.props.form.resetFields()
    onSearch(this.props.form.getFieldsValue())
  }

  render() {
    return (
      <Form
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <Container>
          <Row>{this.renderFormItems(this.props.formitems)}</Row>
        </Container>
        <ErrorFocus />
      </Form>
    )
  }
}

FormGenerator.propTypes = {
  defaultspan: number,
  formitems: arrayOf(
    shape({
      type: oneOf([
        'header',
        'input',
        'select',
        'date',
        'address',
        'checkbox',
        'uploadImg',
        'quest',
        'agreement',
        'submit',
      ]),
      name: string,
      label: oneOfType([object, string]),
      decorators: object,
      isVisible: bool,
    }),
  ),
  form: object,
  onSubmit: func,
}

FormGenerator.defaultProps = {
  defaultspan: 6,
}
export const createForm = () => {
  return FormGenerator
}

import * as Yup from 'yup'
import moment from 'moment'

const SubmitSchema = intl => {
  const requiredMsg = intl.formatMessage({ id: 'error.required' })
  const mustLongMsg = number => {
    return intl.formatMessage({ id: 'error.must_longer' }, { number: number })
  }
  const tooLongMsg = label => {
    return intl.formatMessage({ id: 'error.too_long' }, { label: label })
  }

  return Yup.object().shape({
    nationality: Yup.string().required(requiredMsg),
    first_name_th: Yup.string()
      .min(2, mustLongMsg(2))
      .when('nationality', (nationality, schema) => {
        return nationality === 'TH'
          ? schema.matches(/^[ก-ํ]+$/, intl.formatMessage({ id: 'error.thai_lang_only' })).required(requiredMsg)
          : schema
      }),
    middle_name_th: Yup.string()
      .min(2, mustLongMsg(2))
      .matches(/^[ก-ํ]+$/, intl.formatMessage({ id: 'error.thai_lang_only' })),
    last_name_th: Yup.string()
      .min(2, mustLongMsg(2))
      .when('nationality', (nationality, schema) => {
        return nationality === 'TH'
          ? schema.matches(/^[ก-ํ]+$/, intl.formatMessage({ id: 'error.thai_lang_only' })).required(requiredMsg)
          : schema
      }),
    first_name_en: Yup.string()
      .min(2, mustLongMsg(2))
      .matches(/^[A-z-'‘]+$/, intl.formatMessage({ id: 'error.eng_lang_only' }))
      .required(requiredMsg),
    middle_name_en: Yup.string()
      .min(2, mustLongMsg(2))
      .matches(/^[A-z-'‘]+$/, intl.formatMessage({ id: 'error.eng_lang_only' })),
    last_name_en: Yup.string()
      .min(2, mustLongMsg(2))
      .matches(/^[A-z-'‘]+$/, intl.formatMessage({ id: 'error.eng_lang_only' }))
      .required(requiredMsg),
    prefix: Yup.string().required(requiredMsg),
    sex: Yup.string().required(requiredMsg),
    birth_date: Yup.string()
      .test('20+', intl.formatMessage({ id: 'error.must_be_20' }), val => {
        const birth_year = moment(val, 'YYYY-MM-DD').year()
        const now_year = moment().year()
        return now_year - birth_year >= 20
      })
      .required(requiredMsg),
    phone: Yup.string()
      .min(9, mustLongMsg(9))
      .required(requiredMsg),
    occupation: Yup.string().required(requiredMsg),
    address: Yup.string().required(requiredMsg),
    sub_district: Yup.string().when('nationality', (nationality, schema) => {
      return nationality === 'TH' ? schema.required(requiredMsg) : schema
    }),
    district: Yup.string().when('nationality', (nationality, schema) => {
      return nationality === 'TH' ? schema.required(requiredMsg) : schema
    }),
    province: Yup.string().when('nationality', (nationality, schema) => {
      return nationality === 'TH' ? schema.required(requiredMsg) : schema
    }),
    zip_code: Yup.string().when('nationality', (nationality, schema) => {
      return nationality === 'TH' ? schema.required(requiredMsg) : schema
    }),
    current_address: Yup.string().when('isSameAddress', (isSameAddress, schema) => {
      return isSameAddress !== true ? schema.required(requiredMsg) : schema
    }),
    current_sub_district: Yup.string().when(['nationality', 'isSameAddress'], {
      is: (nationality, isSameAddress) => nationality === 'TH' && !isSameAddress,
      then: Yup.string().required(requiredMsg),
    }),
    current_district: Yup.string().when(['nationality', 'isSameAddress'], {
      is: (nationality, isSameAddress) => nationality === 'TH' && !isSameAddress,
      then: Yup.string().required(requiredMsg),
    }),
    current_province: Yup.string().when(['nationality', 'isSameAddress'], {
      is: (nationality, isSameAddress) => nationality === 'TH' && !isSameAddress,
      then: Yup.string().required(requiredMsg),
    }),
    current_zip_code: Yup.string().when(['nationality', 'isSameAddress'], {
      is: (nationality, isSameAddress) => nationality === 'TH' && !isSameAddress,
      then: Yup.string().required(requiredMsg),
    }),
    national_id: Yup.string()
      .min(10, mustLongMsg(10))
      .when('nationality', (nationality, schema) => {
        return nationality === 'TH'
          ? schema
              .length(13, intl.formatMessage({ id: 'error.must_length' }, { number: 13 }))
              .matches(/[0-9]{13}/, intl.formatMessage({ id: 'error.number_only' }))
              .required(requiredMsg)
          : schema
      }),
    laser_code: Yup.string().when('nationality', (nationality, schema) => {
      return nationality === 'TH'
        ? schema
            .length(12, intl.formatMessage({ id: 'error.must_length' }, { number: 12 }))
            .matches(/^[A-Z]{2}[0-9]{10}$/, intl.formatMessage({ id: 'error.invalid_format' }))
            .required(requiredMsg)
        : schema
    }),
    passport_number: Yup.string()
      .min(6, mustLongMsg(6))
      .max(9, tooLongMsg(intl.formatMessage({ id: 'identityForm.passport' })))
      .when('nationality', (nationality, schema) => {
        return nationality !== 'TH' ? schema.required(requiredMsg) : schema
      }),
    selfie_img: Yup.mixed().required(requiredMsg),
    id_img: Yup.mixed().required(requiredMsg),
    is_us_citizen: Yup.boolean().required(requiredMsg),
    is_us_resident_for_tax: Yup.boolean().required(requiredMsg),
    us_tax_number: Yup.string().when(['is_us_citizen', 'is_us_resident_for_tax'], {
      is: (is_us_citizen, is_us_resident_for_tax) => is_us_citizen || is_us_resident_for_tax,
      then: Yup.string().required(requiredMsg),
    }),
  })
}
export default SubmitSchema

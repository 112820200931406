import React from 'react'
import './style.css'
import { ErrorMessage, Field } from 'formik'
import ReactSelect from 'react-select'
import { string, object, array, oneOfType, func, bool } from 'prop-types'

class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOther: false }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.disabled !== nextProps.disabled ||
      this.props.isRequired !== nextProps.isRequired ||
      this.props.value !== nextProps.value ||
      this.state.isOther !== nextState.isOther
    )
  }

  handleChange = val => {
    this.props.setFieldValue(this.props.name, val.value !== this.props.otherValue ? val.value : '')
    this.props.onChange && this.props.onChange(val.value)
    this.setState({ isOther: this.props.otherValue === val.value })
  }

  handleChangeInputOther = val => {
    this.props.setFieldValue(this.props.name, val.target.value)
    this.props.onChange && this.props.onChange(val.target.value)
  }

  render() {
    const props = this.props
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        height: '42px',
        border: '1px solid #688ffc',
        fontWeight: '400',
        background: state.isDisabled ? '#f4f7ff' : '',
        WebkitBoShadow: state.isFocused ? '0 0 5px  rgba(57,131,250,.5) inset' : '',
        MozBoxShadow: state.isFocused ? '0 0 5px  rgba(57,131,250,.5) inset' : '',
        boxShadow: state.isFocused ? '0 0 5px  rgba(57,131,250,.5) inset' : '',
        '&:hover': {
          border: '1px solid #688ffc',
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '42px',
        display: 'flex',
        position: 'initial',
        flex: 1,
      }),
    }
    const isShowTextOnSelect = props.value !== '' || this.state.isOther
    return (
      <div className="inputGroup">
        <label id={props.name + '_label'}>
          {props.notShowLabel ? '' : props.label}
          {props.isRequired && <span className="is-required">*</span>}
        </label>
        <>
          <ReactSelect
            styles={customStyles}
            id={props.name}
            name={props.name}
            options={props.options}
            placeholder={props.placeholder}
            onChange={val => this.handleChange(val)}
            isDisabled={props.disabled}
            value={isShowTextOnSelect ? props.options.find(option => option.value === props.value) : null}
          />
          {this.state.isOther && (
            <Field
              className="inputFormOther"
              placeholder={props.label}
              name={props.name + 'input'}
              onChange={val => this.handleChangeInputOther(val)}
            />
          )}
        </>

        <ErrorMessage name={props.name} component="div" className="field-error" />
      </div>
    )
  }
}

Select.propTypes = {
  name: string.isRequired,
  label: oneOfType([object, string]).isRequired,
  options: array.isRequired,
  value: string,
  setFieldValue: func.isRequired,
  placeholder: string.isRequired,
  isRequired: bool,
  onChange: func,
  disabled: bool,
  otherValue: string,
}

export default Select

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik } from 'formik'

import { createForm } from '../Form/FormGenerator'
import SubmitSchema from './Schema'
import { getQuestion, submitAnswer } from '../../services/api'
import { RenderQuestionPlaceholder } from '../Form/Question'
import get from 'lodash/get'

const CreateCDDForm = createForm()

class IdentityForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      jwt: props.jwt,
      formId: props.formId,
      userId: props.userId,
      identityId: props.identityId,
      apiKey: props.apiKey,
      redirectURL: props.redirectURL,
      isAgreement: false,
      data: [],
      isLoading: true,
      isModalVisible: false,
      modalHeader: '',
      modalBody: '',
    }
  }

  componentDidMount() {
    getQuestion(this.state.formId, this.state.apiKey)
      .then(res => {
        this.setState({ data: res.data, isLoading: false })
      })
      .catch(err => {
        this.setState({
          isModalVisible: true,
          modalHeader: <FormattedMessage id="cdd.error.get.question.header" />,
          modalBody: <FormattedMessage id="cdd.error.get.question.body" />,
        })
      })
  }

  renderQuest = (values, setFieldValue) => {
    const lang = this.props.intl.locale
    return this.state.data.map(item => {
      const langItem = get(item, `question_${lang}`, '####')
      return {
        type: 'quest',
        name: `${item.question_id}_quest`,
        label: `${item.order}. ${langItem}`,
        options: item.choices ? item.choices.map(choice => {
          return {
            value: choice.id,
            text: choice[`choice_text_${lang}`],
          }
        }) : [],
        span: 12,
        value: values[`${item.question_id}_quest`],
        setFieldValue: setFieldValue,
      }
    })
  }

  onClickAgreement = isAgreement => {
    this.setState({ isAgreement: isAgreement })
  }

  handleSubmit = (values, actions) => {
    const answers = Object.keys(values).map(key => {
      return {
        question_id: parseInt(key.split('_quest')[0]),
        select_choice_id: values[key],
      }
    })

    const data = {
      identity_id: this.state.identityId,
      answers: answers,
    }
    submitAnswer(data, this.state.formId, this.state.apiKey, this.state.jwt)
      .then(res => {
        actions.setSubmitting(false)
        window.location.href = this.state.redirectURL
      })
      .catch(err => {
        actions.setSubmitting(false)
        this.setState({
          isModalVisible: true,
          modalHeader: <FormattedMessage id="cdd.error.submit.answer.header" />,
          modalBody: <FormattedMessage id="cdd.error.submit.answer.body" />,
        })
      })
  }

  handleModalClick = () => {
    this.setState({ isModalVisible: false })
  }

  initialValues = () => {
    const values = {}
    this.state.data.map(item => {
      return (values[item.question_id + '_quest'] = '')
    })
    return values
  }

  render() {
    const { intl } = this.props
    return (
      <>
        <Modal isOpen={this.state.isModalVisible}>
          <ModalHeader>{this.state.modalHeader}</ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button onClick={this.handleModalClick}>Ok</Button>
          </ModalFooter>
        </Modal>
        <Container>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <div className="cdd-session">
                <h4 className="cdd-header-form">
                  <FormattedMessage id="cdd.title" />
                </h4>
                {this.state.isLoading ? (
                  RenderQuestionPlaceholder(10, 4)
                ) : (
                  <Formik
                    initialValues={this.initialValues()}
                    validationSchema={() => SubmitSchema(intl)}
                    onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                    render={({ isSubmitting, handleSubmit, setFieldValue, values }) => (
                      <CreateCDDForm
                        id="createCDDForm"
                        formitems={[
                          ...this.renderQuest(values, setFieldValue),
                          {
                            type: 'agreement',
                            name: 'agreement',
                            label: <FormattedMessage id="cdd.agreement.title" />,
                            checkboxLabel: intl.formatMessage({ id: 'cdd.agreement.i_agree' }),
                            onChange: this.onClickAgreement,
                            options: Array(7)
                              .fill()
                              .map((e, index) => {
                                return {
                                  text: intl.formatMessage({ id: 'cdd.agreement.' + (index + 1) }),
                                }
                              }),
                            tooltip: intl.formatMessage({ id: 'cdd.agreement.scroll_to_check' }),
                            span: 12,
                          },
                          {
                            type: 'submit',
                            name: 'submit',
                            label: <FormattedMessage id="cdd.submit" />,
                            span: 12,
                            handleSubmit: handleSubmit,
                            isSubmitting: isSubmitting,
                            disabled: !this.state.isAgreement,
                          },
                        ]}
                      />
                    )}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default injectIntl(IdentityForm)
